import React, { useEffect } from "react";
import 'react-circular-progressbar/dist/styles.css';

import { Button, Card, Table } from "react-bootstrap";
import { decimalAdjust } from "../../utils/valuesFormater";
import "./ResponsibleParty.css"
import { axiosInstance } from "../../api";
import { Endpoints } from "../../api/endpoints";
import ResponsiblePartyModal from "./ResponsiblePartyModal";

function SetModalAddResponsibleParty(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [refreshResponsibleParty, setRefreshResponsibleParty] = React.useState(false)

  return (
    <div className="ResponsibleModalButton">
      <Button variant="outline-secondary" onClick={() => setModalShow(true)}>Adicionar <i className="fas fa-plus"></i> </Button>
      <ResponsiblePartyModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        refresh={() => setRefreshResponsibleParty(true)}
        head={props.name}
        dataTable={props.dataTable}
      />
    </div>
  );
}

export default function ResponsibleParty() {
  const [data, setData] = React.useState(undefined);
  const [responsiblePartyList, setResponsiblePartyList] = React.useState(undefined);
  const [dataRP, setDataRP] = React.useState(undefined);
  const [year, setYear] = React.useState(localStorage.getItem("year"))
  const [month, setMonth] = React.useState(localStorage.getItem("month"))

  useEffect(() => {
    axiosInstance.get(Endpoints.debt.getDebtresponsibleParties(month, year, undefined))
      .then(res => {
        setData(res.data.map(item => {
          return (
            <tr>
              <td className="td1">{item.name}</td>
              <td className="td1">R$ {decimalAdjust(item.debtValue)}</td>
              <td className="td1">R$ {decimalAdjust(item.walletValue)}</td>
            </tr>
          )
        }));
      })
  }, [])

  useEffect(() => {
    axiosInstance.get(Endpoints.responsibleParty.getByUser())
      .then(res => {
        setDataRP(res.data.map(item => {
          return (
            <tr>
              <td className="td1">{item.name}</td>
            </tr>
          )
        }));
      })
  }, [])
 

  return (
    <div>
      <span id="PagesTitle">Valor por pessoa</span>
      <Card className='cardTable'>
        <Table responsive hover variant="white" className="tableFinancial" size="sm">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Valor a pagar</th>
              <th>Valor a receber</th>
            </tr>
          </thead>
          <tbody>
            {data}
          </tbody>
        </Table>
      </Card>
      
      <SetModalAddResponsibleParty modalName="Adicionar" simbol="fas fa-plus" dataTable={dataRP}></SetModalAddResponsibleParty>
    </div>
  )
}
